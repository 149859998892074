import React, { useContext, useState } from 'react';
import { Box, Grid } from 'grommet';
import CustomButton from '../../components/Button';
import Api from '../../helper/api';
import { Web3Context } from '../../App';

function Download() {
  const api = new Api();
  const webConnect = useContext(Web3Context);
  const [loading, setLoading] = useState<boolean>(false);
  const [link, setLink] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  async function onClickGetLinkButton() {
    try {
      setLoading(true);
      const message =
        webConnect.selectedAccount + ':' + Math.random().toString(4).slice(2);
      const signature = await webConnect.signMessage(message);
      await api.getDownloadToken(message, signature);
      const link = api.getDownloadLink();
      setLoading(false);
      setLink(link);
    } catch (e) {
      setLoading(false);
    }
  }

  function onClickDownloadButton() {
    // @ts-ignore
    window.open(link, '_blank').focus();
    setLink('');
  }

  return (
    <Grid
      rows={['flex']}
      columns={['flex']}
      height={'100%'}
      areas={[{ name: 'main', start: [0, 0], end: [0, 0] }]}
    >
      <Box
        height={'100%'}
        background={'light-3'}
        alignSelf={'center'}
        gridArea={'main'}
        justify="center"
        align="center"
      >
        {!link.length ? (
          <Box alignContent={'center'} key={'generate-button'} margin="small">
            <CustomButton
              onClick={onClickGetLinkButton}
              size={'large'}
              primary
              label={'Generate Link'}
              loading={loading}
            />
          </Box>
        ) : null}
        {link.length ? (
          <Box alignContent={'center'} key={'download-button'} margin="small">
            <CustomButton
              onClick={onClickDownloadButton}
              size={'large'}
              secondary
              label={'Download'}
            />
          </Box>
        ) : null}
      </Box>
    </Grid>
  );
}

export default Download;
