export default class Api {
  baseUrl = 'https://nonoronft-api.herokuapp.com/api';
  token = '';

  getDownloadToken = async (message: string, signature: string) => {
    const rawResponse = await fetch(this.baseUrl + '/generate-link', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message: message, signature: signature }),
    });
    const response = await rawResponse.json();
    this.token = response.url;
  };

  getDownloadLink = () => {
    return `https://nonoronft-api.herokuapp.com/api/download/${this.token}`;
  };

  isDownloadReady = () => {
    return this.token.length !== 0;
  };
}
