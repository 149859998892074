import React, { createContext } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Connect from './pages/Connect';
import PageLayout from './components/PageLayout';
import Web3Connector from './helper/web3-connector';
import Collection from './pages/Collection';
import MyCollection from './pages/MyCollection';
import { PageRoute } from './types';
import Download from './pages/Download';

export const Web3Context = createContext(Web3Connector.prototype);

function App() {
  const web3Connect = new Web3Connector();

  return (
    <Web3Context.Provider value={web3Connect}>
      <BrowserRouter basename={'/'}>
        <Routes>
          <Route path={PageRoute.Root} element={<Connect />} />

          <Route path={PageRoute.Connect} element={<Connect />} />
          <Route element={<PageLayout />}>
            <Route path={PageRoute.Download} element={<Download />} />
            <Route path={PageRoute.Collection} element={<Collection />} />
            <Route path={PageRoute.MyCollection} element={<MyCollection />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Web3Context.Provider>
  );
}

export default App;
