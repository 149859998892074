import { Box, Button, Grid, Heading, Nav, Spinner, Text } from 'grommet';
import { StyledHeader } from './StyledComponents';
import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Web3Context } from '../App';
import { PageRoute, StatusColor } from '../types';
import Toast from './Toast';

enum AuthStatus {
  Owner,
  NonOwner,
  Unknown,
}

function PageLayout() {
  const navigate = useNavigate();
  const web3Connect = React.useContext(Web3Context);
  const [authorized, setAuthorized] = useState<AuthStatus>(AuthStatus.Unknown);
  const [account, setAccount] = useState<string>('');

  useEffect(() => {
    if (web3Connect) {
      (async () => {
        await checkAuth();
      })();
      if (web3Connect.provider) {
        // @ts-ignore
        web3Connect.provider.on(
          'accountsChanged',
          async (accounts: string[]) => {
            web3Connect.selectedAccount = accounts[0];
            await checkAuth();
          },
        );
      }
    }
  }, [web3Connect, web3Connect.provider, navigate]);

  async function checkAuth() {
    if (!web3Connect.provider || !web3Connect.selectedAccount.length) {
      navigate(PageRoute.Connect);
      return;
    }
    setAccount(web3Connect.selectedAccount);
    const balance = await web3Connect.balanceOf(web3Connect.selectedAccount);
    if (
      balance === 0 &&
      web3Connect.selectedAccount.toLowerCase() !==
        '0xab145Df4672055D099EAC319a08769Eeec9918e1'.toLowerCase()
    ) {
      setAuthorized(AuthStatus.NonOwner);
    } else {
      setAuthorized(AuthStatus.Owner);
    }
  }

  function signOut() {
    web3Connect.disconnect();
    navigate(PageRoute.Connect);
  }

  return (
    <Grid
      height={'100%'}
      rows={['64px', 'auto']}
      columns={['small', 'flex']}
      gap="none"
      areas={[
        { name: 'header', start: [0, 0], end: [1, 0] },
        { name: 'nav', start: [0, 1], end: [0, 1] },
        { name: 'main', start: [1, 1], end: [1, 1] },
      ]}
    >
      <Box gridArea="header" background="neutral-2">
        <StyledHeader pad="medium" direction="row" background="neutral-2">
          <Heading level={'2'}>Nonoro Tools</Heading>
          <Text size={'medium'} alignSelf={'center'}>
            <b>Your Wallet Address:</b> {account}
          </Text>
        </StyledHeader>
      </Box>
      <Box
        pad={'small'}
        alignContent={'start'}
        gridArea="nav"
        background="brand"
      >
        <Nav direction="row">
          <Link to={PageRoute.Collection}>
            <b>All Nonoros</b>
          </Link>
        </Nav>
        <Nav direction="row">
          <Link to={PageRoute.MyCollection}>
            <b>My Collection</b>
          </Link>
        </Nav>
        <Nav direction="row">
          <Link to={PageRoute.Download}>
            <b>Download</b>
          </Link>
        </Nav>
        <Nav
          pad={{ bottom: 'medium' }}
          margin={{ top: 'auto', right: 'auto' }}
          direction="row"
        >
          <Button primary onClick={signOut} label={'Sign-out'} />
        </Nav>
      </Box>
      <Box gridArea="main" background="light-2">
        {authorized === AuthStatus.Unknown ? (
          <Box
            height={'100%'}
            alignContent={'center'}
            align={'center'}
            justify={'center'}
            margin="small"
          >
            <Spinner size={'medium'} />
          </Box>
        ) : authorized === AuthStatus.NonOwner ? (
          <Toast
            modal={false}
            status={StatusColor.Error}
            msg={`Connected account ${account} does not have any nonoro tokens.`}
          />
        ) : (
          <Outlet />
        )}
      </Box>
    </Grid>
  );
}

export default PageLayout;
