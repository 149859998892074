import { Box, Button, Layer, Text } from 'grommet';
import { Close } from 'grommet-icons';
import { StatusColor } from '../types';

interface ToastProps {
  onClose?: () => void;
  status?: StatusColor;
  msg: string;
  modal?: boolean;
}

function Toast(props: ToastProps) {
  return (
    <Layer modal={props.modal} full="horizontal" position="top">
      <Box
        background={{ color: props.status }}
        pad="small"
        direction="row"
        justify="between"
        align="center"
      >
        <Text color={'white'} size="medium">
          {props.msg}
        </Text>
        <Button
          style={{ zIndex: 1000 }}
          icon={<Close />}
          onClick={props.onClose}
        />
      </Box>
    </Layer>
  );
}

export default Toast;
