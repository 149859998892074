import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Heading,
  InfiniteScroll,
  Spinner,
} from 'grommet';
import { Web3Context } from '../../App';
import ReactTooltip from 'react-tooltip';

interface Token {
  id: number;
  img: string;
  name: string;
  owner: string;
  hash: string;
}

const size = 5;

function MyCollection() {
  const web3Connect = React.useContext(Web3Context);

  const [page, setPage] = useState(0);
  const [ids, setIds] = useState<number[]>([]);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [totalSupply, setTotalSupply] = useState<number>(0);

  useEffect(() => {
    async function getTokensOfOwner() {
      setIds(await web3Connect.tokensOfOwner(web3Connect.getAddress()));
    }

    async function getBalance() {
      setTotalSupply(await web3Connect.balanceOf(web3Connect.getAddress()));
    }

    getBalance();
    getTokensOfOwner();
    setPage(1);
  }, [web3Connect]);

  useEffect(() => {
    async function getTokens(page: number) {
      if (tokens.length === totalSupply) return;

      const newIds: number[] = ids.slice(
        (page - 1) * size,
        (page - 1) * size + size,
      );
      const newItems = await Promise.all(
        newIds.map(id => {
          return web3Connect.getTokenMetadata(id);
        }),
      );
      setTokens([...tokens, ...newItems]);
    }

    if (totalSupply > 0 && ids.length > 0) getTokens(page);
  }, [page, totalSupply, ids, web3Connect]);

  function onMore() {
    setPage(page + 1);
  }

  return (
    <Box
      height={'100%'}
      width={'100%'}
      overflow={'auto'}
      wrap={true}
      direction="row"
      pad="none"
      justify={'center'}
      alignContent={'start'}
      background={'light-3'}
    >
      {!tokens.length ? (
        <Box
          height={'100%'}
          alignContent={'center'}
          align={'center'}
          justify={'center'}
          margin="small"
        >
          <Spinner size={'medium'} />
        </Box>
      ) : (
        <InfiniteScroll onMore={onMore} step={size} items={tokens}>
          {(t: Token) => (
            <Box key={t.id.toString()} pad="medium" background="light-3">
              <Card
                key={t.id.toString()}
                gridArea={'main'}
                pad={'none'}
                height="190px"
                width="150px"
                background="light-3"
              >
                <CardBody pad="none">
                  <Box pad={'none'} height="100%" width="100%">
                    <img
                      alt={t.name}
                      width={'150px'}
                      height={'150px'}
                      src={t.img}
                    />
                  </Box>
                </CardBody>
                <CardFooter
                  height={'40px'}
                  pad={{ horizontal: 'small' }}
                  background="light-2"
                >
                  <Box width={'small'} pad="small">
                    <Heading
                      data-for={t.id.toString()}
                      data-tip={`<Box width='xlarge'>
                        <ul>
                          <li>
                            <b>Hash:</b>
                            <small>${t.hash}</small>
                          </li>
                          <li>
                            <b>IPFS:</b>
                            <a style="color: dodgerblue" href='${t.img}' target="_blank">Open in new tab</a>
                          </li>
                          <li>
                            <b>Owner:</b>
                            <small>${t.owner}</small>
                          </li>
                        </ul>
                      </Box>`}
                      level={'6'}
                      margin="none"
                    >
                      {t.name}
                    </Heading>
                    <ReactTooltip
                      id={t.id.toString()}
                      html={true}
                      className="extraClass"
                      delayHide={1000}
                      effect="solid"
                    />
                  </Box>
                </CardFooter>
              </Card>
            </Box>
          )}
        </InfiniteScroll>
      )}
    </Box>
  );
}

export default MyCollection;
