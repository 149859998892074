export enum PageRoute {
  Root = '/',
  Connect = '/connect',
  Collection = '/collection',
  MyCollection = '/my-collection',
  Download = '/download',
}

export enum StatusColor {
  Success = 'status-ok',
  Error = 'status-error',
}
