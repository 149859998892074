import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createGlobalStyle } from 'styled-components';

import { globalStyle } from './styles';
import { Grommet } from 'grommet';

const GlobalStyle = createGlobalStyle`
  ${globalStyle}
`;

ReactDOM.render(
  <React.StrictMode>
    <Grommet full={true}>
      <GlobalStyle />
      <App />
    </Grommet>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
